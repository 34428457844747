/* import google fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&family=Open+Sans:wght@400;700&display=swap');

/* simple CSS reset */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* configure CSS variables */
:root {
  /* colors */
  --main-bg: #151515;
  --secondary-bg: #ffffff;
  --text-color: #111111;
  --text-gray: #f2f2f2;
  --main-red: #cc161e;
  --secondary-red: #e23037;
  --main-gray: #f2f2f2;
  --dark-gray: #808080;
  --link-blue: #478ac9;
  --mockup-bg: #f8f9fa;
  /* font-families */
  --basic-font-family: 'Open Sans', sans-serif;
  --hero-font-family: 'Montserrat', sans-serif;
  /* font-size */
  --hero-font-size: clamp(1.875rem, 1.275rem + 2vw, 3.75rem);
  --basic-font-size: 1rem;
  --button-font-size: clamp(1rem, 0.84rem + 0.5333vw, 1.5rem);
  --headline-font-size: clamp(2rem, 1.36rem + 2.1333vw, 4rem);
  --smaller-headline-font-size: clamp(1.5rem, 1rem + 0.5333vw, 1.7rem);
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden !important;
  max-height: 100%;
  height: -webkit-fill-available;
  font-family: var(--basic-font-family);
  color: var(--text-color);
}

body {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

p {
  font-size: var(--basic-font-size);
}

h2 {
  font-size: clamp(1.75rem, 1.35rem + 1.3333vw, 3rem);
  font-weight: 500;
}

.hero-title h1,
.hero-title {
  font-family: var(--hero-font-family);
  font-size: var(--hero-font-size);
}

section {
  margin: auto;
  width: 1140px;
  max-width: 90%;
}

main {
  width: 100%;
  height: 100%;
  margin-top: 95px;
}

nav {
  height: 95px;
}

nav a,
nav span {
  color: var(--secondary-bg);
}

nav a {
  text-decoration: none;
}

.bold {
  font-weight: 700;
}

.light {
  font-weight: 300;
}

.red {
  color: var(--secondary-red);
}

.darkgray {
  color: var(--dark-gray);
}

.textblock {
  margin-block: 1.5rem;
}

.bg-black {
  background-color: #000000;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 2rem;
}

.btn {
  background-color: var(--secondary-red);
  color: var(--secondary-bg);
  font-size: var(--basic-font-size);
  text-transform: uppercase;
  font-weight: 700;
  padding: 15px 30px;
  transition: all 0.3s ease;
  text-decoration: none;
  text-align: center;
  border: 1px solid var(--secondary-red);
  cursor: pointer;
  outline: 1px solid var(--secondary-red);
  outline-color: var(--secondary-red);
  outline-offset: 0px;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}

.btn:hover {
  color: var(--secondary-bg);
  background-color: var(--main-red);
  border: 1px solid var(--secondary-red);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
}

.spot {
  width: 100%;
  height: 62vh;
  object-fit: cover;
  transform: translateY(6px);
}